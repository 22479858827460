<template>
  <div class="activityList wuzq-content">
    <page-content>
      <search-panel
        flex
        show-btn
        @getList="getListChange"
      >
        <div>
          <p>操作日期：</p>
          <el-date-picker
            v-model="timeList"
            type="datetimerange"
            range-separator="至"
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            @change="changeTime( 'time')"
          />
        </div>
        <div>
          <p>价格日期：</p>
          <el-date-picker
            v-model="priceTimeList"
            type="datetimerange"
            range-separator="至"
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            @change="changeTime('price')"
          />
        </div>
        <div>
          <p>全部状态：</p>
          <el-select
            v-model="searchParams.status"
            size="mini"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <p>修改类型：</p>
          <el-select
            v-model="searchParams.updateType"
            size="mini"
            placeholder="请选择类型"
            clearable
          >
            <el-option
              v-for="item in updateTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <p>商品名称：</p>
          <el-select
            v-model="searchParams.specId"
            size="mini"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in specIdOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <p>全部操作人：</p>
          <el-select
            v-model="searchParams.userId"
            size="mini"
            placeholder="请选择操作人"
            clearable
          >
            <el-option
              v-for="item in userIdOptions"
              :key="item.userId"
              :label="item.userNickname"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          slot="button-right"
          type="primary"
          size="mini"
          @click="uploadList"
        >
          导出
        </el-button>
      </search-panel>

      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        style="width: 100%"
        element-loading-text="拼命加载中"
      >
        <el-table-column
          prop="id"
          label="ID"
        />
        <el-table-column
          prop="priceChannel"
          label="价格渠道"
          min-width="110"
        />
        <el-table-column
          prop="specName"
          label="本地房型"
          width="110"
        />
        <el-table-column
          prop="updateType"
          label="修改类型"
          width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.updateType === 1? '修改房价': '关闭库存' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="specValueName"
          label="价格日期"
          min-width="210"
        >
        </el-table-column>
        <el-table-column
          label="修改前价格"
          width="110"
        >
          <template slot-scope="scope">
            ￥{{ scope.row.beforePrice }}
          </template>
        </el-table-column>
        <el-table-column
          label="修改后价格"
          width="110"
        >
          <template slot-scope="scope">
            ￥{{ scope.row.afterPrice }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateUserName"
          label="操作人"
          min-width="110"
        />
        <el-table-column
          label="改价状态"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? '成功' : '失败' }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作时间"
          min-width="210"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */
import dayjs from 'dayjs'

export default {
  name: 'ActivityList',
  data() {
    return {
      statusOptions: [
        { label: '成功', value: 1 },
        { label: '失败', value: 2 }
      ],
      updateTypeOptions: [
        { label: '修改房价', value: 1 },
        { label: '关闭库存', value: 2 }
      ],
      specIdOptions: [],
      userIdOptions: [],
      timeList: [],
      priceTimeList: [],
      searchParams: {
        priceTimeBegin: null, // 价格日期开始
        priceTimeEnd: null,
        updateTimeBegin: null,
        updateTimeEnd: null,
        userId: '', // 操作人
        specId: '', // 房型
        status: null, // 状态
        updateType: null // 类型
      },
      tableData: [],
      loading: false,
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      }
    }
  },
  watch: {
    timeList(newData) {
      if (!newData) {
        newData = []
        this.searchParams.updateTimeBegin = null // 开始日期
        this.searchParams.updateTimeEnd = null // 结束日期
      }
    },
    priceTimeList(newData) {
      if (!newData) {
        newData = []
        this.searchParams.priceTimeBegin = null // 开始日期
        this.searchParams.priceTimeEnd = null // 结束日期
      }
    }
  },
  created() {
    this.getList()
    this.getSpecIdOptions()
    this.getUserIdOptions()
  },
  mounted() {

  },
  methods: {
    getSpecIdOptions() {
      let _this = this
      _this.$axios
        .get(_this.$api.houseChange.house_type_list)
        .then(function(res) {
          if (res.code === 0) {
            _this.specIdOptions = res.data
          }
        }).catch(function(error) {
          console.log(error)
        })
    },
    getUserIdOptions() {
      let _this = this
      _this.$axios
        .get(_this.$api.houseChange.modifier_list)
        .then(function(res) {
          if (res.code === 0) {
            _this.userIdOptions = res.data
          }
        }).catch(function(error) {
          console.log(error)
        })
    },
    // 导出
    uploadList() {
      this.$axios({
        method: 'post',
        url: this.$api.houseChange.export_list,
        data: this.searchParams,
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel',
          crossOrigin: 'Anonymous'
        })
        const objectUrl = URL.createObjectURL(blob)
        window.location.href = objectUrl
      })
    },
    changeTime(type) {
      if (type === 'time') {
        if (this.timeList.length > 0) {
          this.searchParams.updateTimeBegin = this.timeList[0]
          this.searchParams.updateTimeEnd = this.timeList[1]
        } else {
          this.searchParams.updateTimeBegin = null
          this.searchParams.updateTimeEnd = null
        }
      } else {
        if (this.priceTimeList.length > 0) {
          this.searchParams.priceTimeBegin = this.priceTimeList[0]
          this.searchParams.priceTimeEnd = this.priceTimeList[1]
        } else {
          this.searchParams.priceTimeBegin = null
          this.searchParams.priceTimeEnd = null
        }
      }
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    getListChange() {
      this.pagination.page = 1
      this.getList()
    },

    getList() { // index为页面当前页数
      let _this = this
      _this.loading = true
      _this.$axios.post(_this.$api.houseChange.change_price_list,
        {
          ...this.searchParams,
          currentPage: _this.pagination.page,
          pageSize: _this.pagination.page_count
        }
      ).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.tableData = res.data.records
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      }).catch(function(error) {
        console.log(error)
      })
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    }

  }
}
</script>
<style lang="scss" scoped>
.activityList {
  .store_content {
    margin-bottom: 20px;
    span {
      margin-right: 20px;
      color: red;
    }
  }
}

</style>

<style lang="scss">
 .goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }

</style>
